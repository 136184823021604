import React, { createContext, useState, useEffect } from "react";

export const context = createContext();

export function AuthProvider({ children }) {
    const [token, setToken] = useState(() => {
        // Obtener el token almacenado en localStorage al inicio de la aplicación
        const storedToken = localStorage.getItem("token");
        return storedToken || null;
    });

    const handleLogin = (newToken, email) => {
        setToken(newToken);
        localStorage.setItem("token", newToken); // Almacenar el token en localStorage
        localStorage.setItem("email", email); 
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem("token"); // Eliminar el token del localStorage
        localStorage.removeItem("email");
    };

    useEffect(() => {
        // No hagas nada en este efecto, solo se ejecuta una vez al inicio
    }, []);

    return (
        <context.Provider value={{ token, handleLogin, handleLogout }}>
            {children}
        </context.Provider>
    );
}
