import React, { Component, Fragment } from 'react';
import { Spinner } from "react-bootstrap";
import Modal from "../Global/Modal";
import api from '../../helpers/api';
import $ from 'jquery';

class CreateCompra extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: '',
            cantidadOptions: [1,2,3,4,5,6,7,8,9,10],
            productos: [],
            buttonDisable: false,
            loading: false,
            productLoading: true,
            validation: "needs-validation",
            submitClicked: false,
            response: true,
            modal: false,
            data: {
                nombre: '',
                apellido: '',
                dni: 0,
                email: '',
                cantidad: 1,
                producto: 0,
            },
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.modalShowHide = this.modalShowHide.bind(this);
    }

    handleInput(e){
        const { value, name } = e.target;
        this.setState({
            data : {
                ...this.state.data,
                [name] : name === 'dni' || name === "cantidad" || name === "producto" ? parseInt(value, 10) : value
            }
        })
        if(!!this.state.submitClicked){
            setTimeout(()=>{
                this.handleValidation()
            },10)
        }
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const response = await api.getProducts(localStorage.getItem('token'));
        this.setState({
            productos: response.data,
            productLoading: false
        })
    }

    async createCompra(){
        let product = this.state.productos.filter((product)=>parseInt(product.id_woo) === parseInt(this.state.data.producto))
        let metaData = []
        for(let i=0; i<this.state.data.cantidad;i++){
            metaData.push({
                "product" : this.state.data.producto,
                "quantity" : 1,
                "zone" : null,
                "row" : null,
                "seat" : null,
                "days" : null
            })
        }
        const body={
            "order_id": 0,
            "total" : product[0].precio*this.state.data.cantidad,
            "status" : "complete",
            "customer_name" : this.state.data.nombre,
            "customer_lastname" : this.state.data.apellido,
            "customer_email" : this.state.data.email,
            "customer_dni" : this.state.data.dni,
            "meta_data" : metaData
        }
        const response = await api.createCompra(body,localStorage.getItem('token'));
        this.setState({
            response: response.status,
            loading: false,
            modal: true
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            submitClicked: true
        })

        if (this.handleValidation() === 0) {
            this.setState({
                loading: true
            })
            this.createCompra();
        };
    }

    handleValidation() {
        const regex = {
            email: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
            dni: /^\d{7,8}$/,
            cantidad: /^(10|[1-9])$/,
            nombre: /^(?=(.*[A-Za-z]){3,})[A-Za-z ]*$/,
            apellido: /^(?=(.*[A-Za-z]){3,})[A-Za-z ]*$/,
            producto: /^(?!0$)\d+(\.\d+)?$/
        }

        let valudationResultErrors = 0;

        for (const [key, value] of Object.entries(this.state.data)) {
            if (!!regex[key].test(value)) {
                $(`#${key}`).removeClass('is-invalid');
                $(`#${key}`).addClass('is-valid');
            }else{
                $(`#${key}`).removeClass('is-valid');
                $(`#${key}`).addClass('is-invalid');
                valudationResultErrors += 1
            }
        }
        return valudationResultErrors
    }

    modalShowHide(){
        this.setState(prevState => ({
            modal: !prevState
        }));
    }

    render() {
        return (
            <Fragment>
                <Modal response={this.state.response} show={this.modalShowHide} modalShow={this.state.modal} title={'Compras'} success={'La compra se creo con éxito'} error={'Error al crear la compra'}/>
                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Crear compra</h6>
                                </div>
                                <div>
                                    <button className="btn p-0 btn-close-2" onClick={() => this.props.overlayShowHide()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input id="nombre" type="text" className="form-control" name="nombre" placeholder="Nombre" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el nombre</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="apellido">Apellido</label>
                                        <input id="apellido" type="text" className="form-control" name="apellido" placeholder="Apellido" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el apellido</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input id="email" type="text" className="form-control" name="email" placeholder="Email" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa un email válido!</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label htmlFor="dni">DNI</label>
                                            <input id="dni" type="number" className="form-control" name="dni" placeholder="DNI" onChange={this.handleInput} required></input>
                                            <div className="invalid-feedback">Ingresa un dni válido!</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="cantidad">Cantidad</label>
                                            <select id="cantidad" type="text" className="form-control" name="cantidad" onChange={this.handleInput} required>
                                                {this.state.cantidadOptions.map(option=>{
                                                    return <option key={option} value={option}>{option}</option>
                                                })}
                                            </select>
                                            <div className="invalid-feedback">Ingresa una cantidad válida!</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="producto">Entrada</label>
                                        {!this.state.productLoading ?
                                            <>
                                                <select id="producto" type="text" className="form-control" name="producto" onChange={this.handleInput} required>
                                                    <option key={0} value={0}>Selecciona una entrada</option>
                                                    {this.state.productos.map(producto =>{
                                                        if(producto.tipo !== "1"){
                                                            return <option key={producto.id_woo} value={producto.id_woo}>{producto.nombre}</option>
                                                        }else{
                                                            return <Fragment key={producto.id_woo}></Fragment>
                                                        }
                                                    })}
                                                </select>
                                            </>
                                        :
                                            <>
                                                <div className="form-control" name="producto">
                                                    <Spinner></Spinner>
                                                </div>
                                            </>
                                        }
                                        <div className="invalid-feedback">Selecciona una enrtada!</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-stretch">
                            <button disabled={this.state.loading} className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>{this.state.loading ? <Spinner/> : 'Crear compra'}</button>
                            <button disabled={this.state.loading} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}


export default CreateCompra;