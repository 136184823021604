import React from "react";
import "../components/Global/global.scss";
import { Col, Container, Row } from "react-bootstrap";
import DataTableCompras from "../components/Compras/ListCompras";
import Navigation from '../components/Navigation/Navigation';
// import SecondaryNavigationEmpty from '../components/SecondaryNavigation/SecondaryNavigationEmpty';

export default function CheckProjects() {
  return (
    <div>
      <Navigation />
      {/* <SecondaryNavigationEmpty title="Compras" /> */}
      <Container>
        <Row>
          <Col className="mt-4">
            <div className="box-page">
              <DataTableCompras />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
