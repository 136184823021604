import React, { Component } from 'react';
// import logo from './../../assets/images/carta-on-line-logo.png';
import { Link, Navigate } from 'react-router-dom';
import './NavigationCss.css';


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuLeft: false,
            redirect: false
        }
        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
        this.logout = this.logout.bind(this);
    }

    style={
        zIndex:1000
    }

    handleCloseMenu() {
        this.setState({
            menuLeft: false
        })
    }

    handleOpenMenu() {
        this.setState({
            menuLeft: true
        })
    }

    logout() {
        localStorage.clear();
        this.setState({
            redirect: true
        })
    }

    render() {
        if (this.state.redirect) {
            return (
                <Navigate to={"/login"}/>
            )
        } else {
            return (
                <header>
                    <nav className="navbar navbar-light bg-light d-flex justify-content-between">
                        <button className="btn btn-outline-grey" onClick={this.handleOpenMenu}>
                            <i className="fas fa-bars"></i> Menú
                    </button>
                        <div className="logo">
                            <Link to="/escritorio">
                                {/* <img src={logo} alt="" /> */}
                            </Link>
                        </div>
                        <div className="btn-group d-xl-block d-lg-block d-md-block d-sm-none d-none" role="group">
                            <button id="UserSelect" type="button" className="btn btn-outline-grey dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {localStorage.getItem('email')}
                            </button>
                            <div className="dropdown-menu collapse" aria-labelledby="UserSelect" style={this.style}>
                                <button onClick={this.logout} className="dropdown-item">Cerrar Sesión</button>
                            </div>
                        </div>
                    </nav>
                    {(() => {
                        switch (this.state.menuLeft) {
                            case true:
                                return (
                                    <div className="collapse-menu">
                                        <div className="collapse-menu-content">
                                            <div className="close">
                                                <button onClick={this.handleCloseMenu}>
                                                    <i className="fas fa-times mr-1"></i> <b>Cerrar menú</b>
                                                </button>
                                            </div>
                                            <ul className="content">
                                                <li>
                                                    <Link to="/dashboard" onClick={this.handleCloseMenu}>
                                                        Dashboard
                                                    </Link>
                                                </li>
                                                <li className="header">
                                                    <b>Administración</b>
                                                </li>
                                                <li>
                                                    <Link to="/compras" onClick={this.handleCloseMenu}>
                                                        Compras
                                                    </Link>
                                                </li>
                                                <li className="header">
                                                    <b>Informes</b>
                                                </li>
                                                <li>
                                                    <Link to="/informes" onClick={this.handleCloseMenu}>
                                                        Informes
                                                    </Link>
                                                </li>

                                                <div className="btn-group d-xl-none d-lg-none d-md-none d-sm-block d-block" role="group">
                                                    <button id="UserSelect" type="button" className="d-block btn btn-outline-grey dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {localStorage.getItem('email')}
                                                    </button>
                                                    <div className="dropdown-menu collapse" aria-labelledby="UserSelect">
                                                        <div onClick={this.logout} className="dropdown-item">Cerrar Sesión</div>
                                                    </div>
                                                </div>
                                            </ul>
                                            <span className='ps-3 pb-2'>Powered by Kloudlab</span>
                                        </div>
                                    </div>
                                );
                            case false:
                                return;
                            default:
                                return;
                        }
                    })()}
                </header>
            )
        }
    }
}
export default Navigation;