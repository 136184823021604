import { Component, Fragment } from 'react';

class PdfViewer extends Component {

    render(){
        return(
            <Fragment>
            {this.props.modalShow ?
                <div className="custom-modal">
                    <div className="custom-modal-content-pdf">
                        <div className="custom-modal-header">
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h6>Entrada</h6></div>
                                <div>
                                    <button className="btn p-0 btn-close-2" onClick={this.props.show}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="right-body bg-white text-center">
                            <div>
                                <iframe id="if1" title='pdf-viewer' width="100%" style={{visibility:'visible',height:"50vh"}} src={this.props.data}></iframe>
                            </div>
                        </div>

                        <div className="right-footer d-flex justify-content-center align-items-stretch">
                            <button className="btn btn-secondary mr-2" onClick={this.props.show}>
                                    Cerrar
                            </button>
                        </div>

                    </div>
                </div>
            :
                <></>
            }
            </Fragment>
        )
    }
}

export default PdfViewer;