import React, { Component } from 'react';
import './RightOverlay.css';

class RightOverlay extends Component {

    constructor(props) {
        super(props);
        this.state={
            
        }
    }

    render() {
        if (this.props.overlayShow === false) {
            return false;
        }
        else {
            return (
                <div className="right-overlay">
                    {<this.props.overlayContent id={this.props.id} overlayShowHide={this.props.overlayShowHide} cliente={this.props.cliente} />}
                </div>
            )
        }
    }
}
export default RightOverlay;