import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../helpers/api';
import Navigation from '../components/Navigation/Navigation';
import SecondaryNavigationEmpty from '../components/SecondaryNavigation/SecondaryNavigationEmpty';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';

import './dashboard.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, Tooltip, Legend);


export default function Dashboard () {

    const [totalSales, setTotalSales] = useState(0);
    const [qlokura, setqlokura] = useState(0);
    const [fuentes, setfuentes] = useState(0);
    const [salesPerMonthDataLabels, setSalesPerMonthDataLabels] = useState([]);
    const [salesPerType, setsalesPerType] = useState([]);
    const [salesPerTypeLabels, setsalesPerTypeLabels] = useState([]);
    const [salesPerMonth, setSalesPerMonth] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const totalAvailable = 3420;

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchData = async () => {
        try {
            const response = await api.getCompras(localStorage.getItem('token'));
            summarizeTickets(response.data)
            setMonthLabels(response.data)
            const responseEntradas = await api.getEntradas(localStorage.getItem('token'));
            entradasType(responseEntradas.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const summarizeTickets = (sales) => {
        let count = 0;
        let totalAmount = 0; 
        for(let sale of sales){
            count += sale.cantidad*1
            totalAmount += sale.monto*1
        }
        setTotalSales(count)
        setTotalAmount(totalAmount)
    }

    const entradasType = (entradas) => {
        const groupedByNombre = entradas.reduce((acc, obj) => {
            if (!acc[obj.nombre]) {
                acc[obj.nombre] = 0;
            }
            acc[obj.nombre]++;
            return acc;
        }, {});
        setsalesPerTypeLabels(Object.keys(groupedByNombre));
        setsalesPerType(Object.values(groupedByNombre));
        let qlokuraCount = 0;
        let fuentesCount = 0;
        for(let entrada of entradas){
            if( entrada.producto === "1" ){
                fuentesCount+=1;
            }else if( entrada.producto === "2"){
                qlokuraCount+=1;
            }
        }
        setqlokura(qlokuraCount);
        setfuentes(fuentesCount);
    }

    const setMonthLabels = (sales) => {
        const monthOrder = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        const totalsByMonth = {};
        sales.forEach((obj) => {
            const date = new Date(obj.created_at);
            const monthYear = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

            if (!totalsByMonth[monthYear]) {
                totalsByMonth[monthYear] = {
                    month: date.toLocaleString('default', { month: 'long' }), // Full month name
                    year: date.getFullYear(),
                    total: 0,
                    count: 0,
                };
            }

            totalsByMonth[monthYear].total += obj.cantidad*1;
            totalsByMonth[monthYear].count++;
        });
        let arrayMonthLabels = [];
        let arrayMonthSales = [];
        for(let [, value] of Object.entries(totalsByMonth)){
            arrayMonthLabels.push(value.month.charAt(0).toUpperCase() + value.month.slice(1))
            arrayMonthSales.push(value.total);
        }
        arrayMonthLabels.sort((a, b) => {
            return monthOrder.indexOf(a) - monthOrder.indexOf(b);
        });
        setSalesPerMonthDataLabels(arrayMonthLabels)
        setSalesPerMonth(arrayMonthSales)
    }

    const totalSalesData = {
        labels: [`Disponibles: ${totalAvailable-totalSales}`, `Fuentes: ${fuentes}`, `Q´lokura: ${qlokura}`],
        datasets: [
            {
                label: 'N° de entradas',
                data: [totalAvailable-totalSales, fuentes, qlokura],
                backgroundColor: ['rgba(51, 131, 255, 0.2)','rgba(51, 255, 59, 0.2)','rgba(255, 0, 0, 0.2)'],
                borderColor: ['rgba(51, 131, 255, 1)','rgba(51, 255, 59, 1)','rgba(255, 0, 0, 1)'],
                borderWidth: 1,
            },
        ],
    };

    const totalSalesOptions = {
        responsive: true
    }

    const salesPerMonthData={
        labels: salesPerMonthDataLabels,
        datasets: [
            {
                label: 'Monthly Sales',
                data: salesPerMonth,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    }

    const salesPerMonthOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Monthly Sales Data',
            }
        }
    }

    const salesPerTypeData = {
        labels: salesPerTypeLabels,
        datasets: [
            {
                label: 'Total',
                data: salesPerType,
                backgroundColor: ['rgba(51, 255, 59, 0.2)','rgba(51, 131, 255, 0.2)','rgba(255, 0, 0, 0.2)'],
                borderColor: ['rgba(51, 255, 59, 1)','rgba(51, 131, 255, 1)','rgba(255, 0, 0, 1)'],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Navigation />
            <SecondaryNavigationEmpty title="Dashboard"/>
            <div className="container mt-4">
                <div className="row mb-2">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ">
                        <div className="card mb-2 shadow-sm border-0 w-100 pb-2">
                            <div className="card-body p-4">
                                <h5 className="card-title">Balance total</h5>
                                <hr />
                                <h4>${totalAmount.toLocaleString('de-DE')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12 ">
                        <div className="card mb-2 shadow-sm border-0 w-100 pb-2">
                            <div className="card-body p-4">
                                <h5 className="card-title">Entradas vendidas</h5>
                                <hr />
                                <div className="d-fle align-items-center justify-content-between">
                                    <h4>{totalSales}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12 ">
                        <div className="card mb-2 shadow-sm border-0 w-100 pb-2">
                            <div className="card-body p-4">
                                <h5 className="card-title">Ocupacion Fuentes</h5>
                                <hr />
                                <h4>{(fuentes*100/(totalAvailable/2)).toLocaleString('de-DE',{minimumFractionDigits: 2,maximumFractionDigits: 2})}%</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12 ">
                        <div className="card mb-2 shadow-sm border-0 w-100 pb-2">
                            <div className="card-body p-4">
                                <h5 className="card-title">Ocupacion Q'Lokura</h5>
                                <hr />
                                <h4>{(qlokura*100/(totalAvailable/2)).toLocaleString('de-DE',{minimumFractionDigits: 2,maximumFractionDigits: 2})}%</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ">
                        <div className="card mb-2 shadow-sm border-0 w-100">
                            <div className="card-body p-4">
                                <h5 className="card-title">Aplicaciones</h5>
                                <hr />
                                <div className="d-fle align-items-center justify-content-between">
                                    <Link to="/compras">
                                        <button type="button" className="btn btn-secondary mr-2">
                                            Compras
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="card shadow-sm border-0 w-100">
                            <div className='total-sales-chart d-flex flex-column align-items-start'>
                                <div className="card-body p-3 w-100">
                                    <h5 className="card-title ">Venta de plateas</h5>
                                    <hr/>
                                    <Doughnut data={totalSalesData} options={totalSalesOptions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="card shadow-sm border-0 w-100">
                            <div className="total-sales-chart d-flex flex-column align-items-start">
                                <div className="card-body p-3 w-100">
                                    <h5 className="card-title">Ventas por mes</h5>
                                    <hr />
                                    <Bar data={salesPerMonthData} options={salesPerMonthOptions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="card shadow-sm border-0 w-100">
                            <div className='total-sales-chart d-flex flex-column align-items-start'>
                                <div className="card-body p-3 w-100">
                                    <h5 className="card-title ">Ventas por tipo</h5>
                                    <hr/>
                                    <Pie data={salesPerTypeData} options={totalSalesOptions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}