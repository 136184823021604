import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./pages/dashboard";
import Compras from "./pages/Compras";
import Informes from "./pages/Informes";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { AuthProvider } from "./context/authContext";

import "./App.scss";
import './index.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route element={<PrivateRoute/>}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/compras" element={<Compras />} />
              <Route exact path="/informes" element={<Informes />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
