import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { context } from '../../context/authContext';

export default function PrivateRoute() {
  const { token } = useContext(context);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
