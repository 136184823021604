import React, { useState, useEffect } from "react";
import RightOverlay from '../RightOverlay/RightOverlay';
import ShowTickets from '../Compras/ShowTickets';
import CreateCompra from '../Compras/CreateCompra';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext"; // Importar InputText para el buscador
import "primereact/resources/primereact.min.css"; // Estilos de PrimeReact
import "primeicons/primeicons.css"; // Iconos de PrimeReact
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import { Spinner } from "react-bootstrap";
import api from '../../helpers/api';

export default function MyDataTable() {
  const [data, setData] = useState([]);
  const [cliente, setCliente] = useState();
  const [dni, setDni] = useState();
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [overlayEditShow, setOverlayEditShow] = useState(false);
  const [overlayCreateShow, setOverlayCreateShow] = useState(false);
  const [IdEdit, setIdEdit] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(""); // Estado para el valor del filtro de búsqueda

  useEffect(() => {
    setLoading(true)
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update]);

  const fetchData = async () => {
    try {
      const response = await api.getCompras(localStorage.getItem('token'));
      formatData(response.data.reverse())
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false)
  };

  const formatData = (data) =>{
    for(let transaction of data){
      let number = transaction.monto*1
      transaction.formatMonto = `$${number.toLocaleString('de-DE')}`
      transaction.cliente = `${transaction.nombre} ${transaction.apellido}`
    }
    setData(data);
  }

  const overlayShowHide = () => {
    setOverlayEditShow(false);
    setIdEdit(null)
  }
  
  const overlayCreateShowHide = () => {
    setOverlayCreateShow(false);
  }

  const showTickets = (id,cliente,dni) =>{
    setOverlayEditShow(() => ({overlayEditShow: true}));
    setIdEdit(id)
    setCliente(cliente)
    setDni(dni)
  }

  const createCompraOverlay = () =>{
    setOverlayCreateShow(()=>({overlayCreateShow: true}));
  }

  const refresh = () => {
    setUpdate(!update)
  }

  return (
    <>
      <RightOverlay id={IdEdit} overlayContent={ShowTickets} overlayShowHide={overlayShowHide} overlayShow={overlayEditShow} cliente={{nombre:cliente, dni:dni}}/>
      <RightOverlay overlayContent={CreateCompra} overlayShowHide={overlayCreateShowHide} overlayShow={overlayCreateShow}/>
      <div className="w-100 d-flex justify-content-between">
        <h5>Compras</h5>
        <div>
          <button className="btn btn-primary mb-2 me-2" onClick={() => {createCompraOverlay()}}>Crear compra</button>
          <button className="btn btn-secondary mb-2" onClick={refresh}>Actualizar</button>
        </div>
      </div>
      <div className="card">
        <div className="p-input-icon-left m-3">
          <InputText
            type="search"
            placeholder="Buscar..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-100"
          />
        </div>
        {!!loading ? 
          <div className="w-100 d-flex justify-content-center mb-4">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </div>
        :
          <DataTable
            value={data}
            paginator
            rowsPerPageOptions={[5,10,25,50,100]}
            stripedRows
            rows={5}
            globalFilter={globalFilter}
            className="custom-datatable striped bordered hover"
          >
            <Column sortable key='id' field='id' header='Id' />
            <Column key='order_id' field='order_id' header='Id woo' />
            <Column key='email' field='email' header='Email' />
            <Column key='cliente' field='cliente' header='Cliente' />
            <Column key='dni' field='dni' header='DNI' />
            <Column sortable key='formatMonto' field='formatMonto' header='Monto' />
            <Column key='estado' field='estado' header='Estado' />
            <Column key='cantidad' field='cantidad' header='Cantidad' />
            <Column key='created_at' field='created_at' header='Fecha' />
            <Column header='Entradas' body={(data => <button className="btn btn-secondary" onClick={() => {showTickets(data.id, data.cliente, data.dni)}}>Ver</button>)} />
          </DataTable>
        }
      </div>
    </>
  );
}
