import React, { Component, Fragment } from 'react';
import { Spinner } from "react-bootstrap";
import PdfViewer from '../Global/PdfViewer'
import qr from '../../assets/images/commons_QR_code.png'
import api from '../../helpers/api';
import Modal from "../Global/Modal";

class ShowTickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: '',
            entradas: [],
            loading: false,
            pdf: false,
            pdfData: null,
            modal: false,
            response: null,
        };
        this.pdfShowHide = this.pdfShowHide.bind(this);
        this.modalShowHide = this.modalShowHide.bind(this);

    }

    componentDidMount() {
        this.getData()
    }

    pdfShowHide(){
        this.setState(prevState => ({
            pdf: !prevState
        }));
    }

    async getData() {
        const response = await api.getEntradasXCompra(this.props.id,localStorage.getItem('token'));
        this.setState({
            entradas: response.data
        })
    }

    async blobToArrayBuffer (blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    };

    async getTicket(ruta, download=false){
        if(!this.state.loading){
            this.setState({loading:true})
            const response = await api.getTicket(ruta,localStorage.getItem('token'));

            if(download){
                const blobUrl = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blobUrl;
                a.download = 'Ticket-Finaco.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                this.setState({loading:false})
            }else{
                this.setState({
                    loading:false,
                    pdfData: URL.createObjectURL(response.data)
                })
                setTimeout(()=>{
                    this.setState({
                        pdf: true
                    })

                },10)
            }
            
        }
    }

    modalShowHide(){
        this.setState(prevState => ({
            modal: !prevState
        }));
    }

    async reenviarMailCompra(idCompra){
        this.setState({loading:true})
        const response = await api.reenviarMail(idCompra,localStorage.getItem('token'));
        if (response.status === 200){
            this.setState({modal:true, response: response.status})
        }
        this.setState({loading:false})
    }

    render() {
        return (
            <Fragment>
                <Modal response={this.state.response} show={this.modalShowHide} modalShow={this.state.modal} title={'Reenviar mail'} success={'Mail reenviado correctamente'} error={'Error al reenviar mail'}/>
                <PdfViewer show={this.pdfShowHide} modalShow={this.state.pdf} data={this.state.pdfData}/>
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Entradas</h6>
                            </div>
                            <div>
                                <button className="btn p-0 btn-close-2" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
                                    <h6 className='m-0'>{this.props.cliente.nombre}</h6>
                                    <p className='m-0'>{this.props.cliente.dni}</p>
                                    <button disabled={this.state.loading} className='btn btn-primary me-2' onClick={()=>this.reenviarMailCompra(this.props.id)}>{this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />: 'Reenviar'}</button>
                                </div>
                                <div className="divider mt-1 mb-3"></div> 
                                {this.state.entradas.length === 0 ? 
                                    <Spinner/>
                                :
                                    this.state.entradas.map((entrada)=>
                                    <Fragment key={entrada.id}>
                                        {entrada.tipo === '1' ?
                                            <>
                                                <div className='entrada-ticket d-flex justify-content-between'>
                                                    <div>
                                                        <p>Tipo: {entrada.nombreTipo}</p>
                                                        <p>Zona: {entrada.zona} - Fila: {entrada.fila}</p>
                                                        <p>Asiento: {entrada.asiento}</p>
                                                    </div>
                                                    <div className='qr d-flex flex-column justify-content-between align-items-end'>
                                                        <img className="mb-3" width={100} height={100} src={qr} alt='Qr'/>
                                                        <div className='d-flex flex-row'>
                                                            <button disabled={this.state.loading} className='btn btn-primary me-2' onClick={()=>this.getTicket(entrada.ruta_pdf, false)}>{this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />: 'Imprimir'}</button>
                                                            <button disabled={this.state.loading} className='btn btn-secondary' onClick={()=>this.getTicket(entrada.ruta_pdf, true)}>{this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />: 'Descargar'}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divider mt-5 mb-5"></div>
                                            </>   
                                        :
                                            <>
                                                <div className='entrada-ticket d-flex justify-content-between'>
                                                    <div>
                                                        <p>Tipo: {entrada.nombreTipo}</p>
                                                    </div>
                                                    <div className='qr d-flex flex-column justify-content-between align-items-end'>
                                                        <img className='mb-3' width={100} height={100} src={qr} alt='Qr'/>
                                                        <div className='d-flex flex-row'>
                                                            <button disabled={this.state.loading} className='btn btn-primary me-2' onClick={()=>this.getTicket(entrada.ruta_pdf, false)}>{this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />: 'Imprimir'}</button>
                                                            <button disabled={this.state.loading} className='btn btn-secondary' onClick={()=>this.getTicket(entrada.ruta_pdf, true)}>{this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />: 'Descargar'}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divider mt-5 mb-5"></div>   
                                            </>
                                        }
                                    </Fragment>
                                            
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.loading} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>Cancelar</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default ShowTickets;