import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate desde react-router-dom
import { Button, Alert, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Animated } from "react-animated-css";
import { context } from "../../context/authContext";
import api from '../../helpers/api';

import "./Login.scss";

export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { token, handleLogin } = useContext(context);
  const navigate = useNavigate();

  if (token) {
    navigate("/dashboard");
  };

  const onSubmit = async (data) => {
    setLoginError(null);
    setIsLoading(true);
    try {
      const response = await api.login(data);

      if (!response.data || !response.data.token) {
        throw new Error("Invalid response from server");
      }

      handleLogin(response.data.token, response.data.user.email);
      navigate("/dashboard");
    } catch (error) {
      setLoginError("Incorrect email or password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="background-login">
      <div className="login-box-splash">
        {/* <div className="box-splash">
          <div className="title-simbol">
            <div className="title-simbol-circle"></div>
          </div>
          <h1 className="mt-4">
            Maximize the impact and efficiency of <b>marketing technology</b>
          </h1>
          <img className="mt-5" width="180" src="../../../img/Logo_Xplit_Blanco.png" alt="Kloudlab" />
        </div> */}
      </div>
      <div className="container-login-box-white">
        <div className="box-white">
          {/* <div className="text-center">
            <img width="200" className="mb-5" src="../../../img/engbim-logo.svg" alt="ENG" />
          </div> */}
          <hr />
          <h5 className="mb-4">Log in</h5>
          {loginError && (
            <Animated animationIn="shake" animationOut="fadeOut" isVisible={true}>
              <Alert variant="danger">{loginError}</Alert>
            </Animated>
          )}
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="email@example.com"
                name="email"
                {...register("email", { required: true })}
                isInvalid={errors.email}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="***********"
                name="password"
                {...register("password", { required: true })}
                isInvalid={errors.password}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  Please enter a password.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button className="mt-4" variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Log in"
              )}
            </Button>
          </Form>
          {/* <div className="text-center mt-4 logo-login">
            <img className="mt-5" width="180" src="../../../img/Logo_Xplit_Blanco.png" alt="Kloudlab" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
